
import { Options, Vue } from 'vue-class-component';
import { CustomerClient } from '@/services/services';
import * as OM from '@/model';

@Options({
    components: {}
})
export default class PersonalInfo extends Vue {

    customer: OM.Customer = new OM.Customer();
    loaded: boolean = false;

    created() {
        var userId = this.$route.params.identifier.toString();
        CustomerClient.getProfile()
        .then(x => {
            this.customer = x;
            this.loaded = true;
        })
    }

    get gender() {
        if(this.customer.isMale == null)
            return this.$localizationService.getLocalizedValue("app_NotSpecified", "Not specified");
        else if(this.customer.isMale)
            return this.$localizationService.getLocalizedValue("app_Male", "Male");
        else
            return this.$localizationService.getLocalizedValue("app_Female", "Female");
    }
}
